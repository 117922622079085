import React from 'react';
import classNames from 'classnames';
import { ButtonVariantProps } from '../Button.types';

export const APPButtonOutlined: React.FC<ButtonVariantProps> = ({
  children,
  className,
  color,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        className,
        'text-gray-400 border-solid bg-transparent border-gray-400',
      )}
      style={{ borderColor: color }}
      {...rest}
    >
      {children}
    </button>
  )
}
