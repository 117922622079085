import React from 'react';
import classNames from 'classnames';
import { ButtonVariantProps } from '../Button.types';

export const CMSButtonOutlined: React.FC<ButtonVariantProps> = ({
  children,
  className,
  color,
  danger,
  success,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        className,
        'text-zinc-600 border-solid bg-transparent border-gray-200',
        { 'border-red-500 text-red-500': danger },
        { 'border-lime-500 text-lime-500': success }
       )}
      style={{
        borderColor: color,
        color
      }}
      {...rest}
    >
      {children}
    </button>
  )
}
