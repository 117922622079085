import React from 'react';
import classNames from 'classnames';
import { ButtonProps } from '../Button.types';
import { CMSButtonContained } from './Button.contained';
import { CMSButtonOutlined } from './Button.outlined';
import { CMSButtonText } from './Button.text';

export const BASE_CLASSES = 'box-border focus:outline-none cursor-pointer flex justify-center items-center text-center border-2 text-sm rounded-full py-3 px-7'

export const CMSButton: React.FC<ButtonProps> = ({
  variant,
  className,
  children,
  startIcon,
  endIcon,
  ...rest
}) => {
  const Button = {
    contained: CMSButtonContained,
    outlined: CMSButtonOutlined,
    text: CMSButtonText,
  }[variant]

  return (
    <Button className={classNames(BASE_CLASSES, className)} {...rest}>
      {startIcon && <span className='pr-4'>{startIcon}</span>}
      <span>{children}</span>
      {endIcon && <span className='pl-4'>{endIcon}</span>}
    </Button>
  )
}