import React from 'react';
import classNames from 'classnames';
import { ButtonVariantProps } from '../Button.types';

export const CMSButtonText: React.FC<ButtonVariantProps> = ({
  children,
  className,
  textColor,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        className, 
        'text-zinc-600 border-none bg-transparent px-2'
      )}
      style={{ color: textColor }}
      {...rest}
      >
        {children}
    </button>
  )
}
