import React, { useContext, createContext } from 'react';

export type Layout = 'cms' | 'app'

export type LayoutClasses<Variants extends string | number | symbol = any> =
  Record<Layout, { [key in Variants]: string } | string>

interface MobieTrainUIProps {
  layout: Layout
  children: React.ReactNode
}

const MobieTrainUIContext = createContext<Layout | null>(null)

export function useLayout() {
  const layout = useContext(MobieTrainUIContext);
  if (!layout) throw new Error(`layout is not defined`)
  return layout
}

export function useClasses<V extends string | number | symbol>(layouts: LayoutClasses<V>, variant?: V): string {
  const layout = useLayout()

  const layoutClasses = layouts[layout]
  if (typeof layoutClasses === 'string') return layoutClasses

  if (!variant) throw new Error('variant must be given')
  return layoutClasses[variant]
}

export const MobieTrainUI: React.FC<MobieTrainUIProps> = ({ layout, children }) => {
  return (
    <MobieTrainUIContext.Provider value={layout}>
      {children}
    </MobieTrainUIContext.Provider>
  )
}
