import React from 'react';
import classNames from 'classnames';
import { ButtonProps } from '../Button.types';
import { APPButtonContained } from './Button.contained';
import { APPButtonOutlined } from './Button.outlined';
import { APPButtonText } from './Button.text';

export const BASE_CLASSES = 'box-border focus:outline-none font-semibold cursor-pointer flex justify-between p-4 rounded-sm border-2'

export const APPButton: React.FC<ButtonProps> = ({
  variant,
  className,
  children,
  startIcon,
  endIcon,
  ...rest
}) => {
  const Button = {
    contained: APPButtonContained,
    outlined: APPButtonOutlined ,
    text: APPButtonText,
  }[variant]

  return (
    <Button className={classNames(BASE_CLASSES, className)} {...rest}>
      <div>
        {startIcon && <span className='pr-4'>{startIcon}</span>}
        {children && <span>{children}</span>}
      </div>
      {endIcon && <span className='pl-4'>{endIcon}</span>}
    </Button>
  )
}
