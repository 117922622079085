import React from 'react';
import classNames from 'classnames';
import { ButtonVariantProps } from '../Button.types'

export const APPButtonContained: React.FC<ButtonVariantProps> = ({
  children,
  className,
  color,
  textColor,
  danger,
  success,
  rounded,
  centered,
  ...rest
}) => {
  return (
    <button
      className={classNames(
        className,
        'text-zinc-600 bg-gray-200 border-gray-200',
        { 'border-red-500 bg-red-500': danger },
        { 'border-lime-500 bg-lime-500': success },
        { '!rounded-full p-5': rounded },
        { '!justify-center': centered },
      )}
      style={{
        borderColor: color,
        backgroundColor: color,
        color: textColor
      }}
      {...rest}
    >
      {children}
    </button>
  )
}
