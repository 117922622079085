import React from 'react';
import classNames from 'classnames';
import { useLayout } from '../../mobietrain-ui';
import { APPButton } from './app/Button';
import { BaseButtonProps } from './Button.types';
import { CMSButton } from './cms/Button';

export const Button: React.FC<BaseButtonProps> = ({
  variant = 'text',
  disabled = false,
  fullWidth = false,
  bold,
  className,
  ...props
}) => {
  const layout = useLayout()

  return {
    app: <APPButton
      className={classNames(
        className,
        { 'w-full': fullWidth },
        { 'opacity-30 cursor-not-allowed': disabled },
      )}
      variant={variant}
      disabled={disabled}
      {...props}
    />,
    cms: <CMSButton
      className={classNames(
        className,
        {'text-zinc-600 h-full font-bold !bg-gray-200 !border-none w-full !py-5 !rounded-none': fullWidth},
        { 'opacity-30 cursor-not-allowed': disabled },
        { 'font-bold': bold },
      )}
      variant={variant}
      disabled={disabled}
      {...props}
    />
  }[layout]
};